
/*
	Copyright (C) Hoefler & Co.
	This software is the property of Hoefler & Co. (H&Co).
	Your right to access and use this software is subject to the
	applicable License Agreement, or Terms of Service, that exists
	between you and H&Co. If no such agreement exists, you may not
	access or use this software for any purpose.
	This software may only be hosted at the locations specified in
	the applicable License Agreement or Terms of Service, and only
	for the purposes expressly set forth therein. You may not copy,
	modify, convert, create derivative works from or distribute this
	software in any way, or make it accessible to any third party,
	without first obtaining the written permission of H&Co.
	For more information, please visit us at http://typography.com.
*/

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-Thin_Web.woff2') format('woff2'),
		url('./woff/Tungsten-Thin_Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-XLight_Web.woff2') format('woff2'),
		url('./woff/Tungsten-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-Light_Web.woff2') format('woff2'),
		url('./woff/Tungsten-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-Book_Web.woff2') format('woff2'),
		url('./woff/Tungsten-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-Medium_Web.woff2') format('woff2'),
		url('./woff/Tungsten-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-Semibold_Web.woff2') format('woff2'),
		url('./woff/Tungsten-Semibold_Web.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-Bold_Web.woff2') format('woff2'),
		url('./woff/Tungsten-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten';
	src:url('./woff2/Tungsten-Black_Web.woff2') format('woff2'),
		url('./woff/Tungsten-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-Thin_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-Thin_Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-XLight_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-Light_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-Book_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-Medium_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-Semibold_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-Semibold_Web.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-Bold_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Comp';
	src:url('./woff2/TungstenComp-Black_Web.woff2') format('woff2'),
		url('./woff/TungstenComp-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-Thin_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-Thin_Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-XLight_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-Light_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-Book_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-Medium_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-Semibold_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-Semibold_Web.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-Bold_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Cond';
	src:url('./woff2/TungstenCond-Black_Web.woff2') format('woff2'),
		url('./woff/TungstenCond-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-Thin_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-Thin_Web.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-XLight_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-XLight_Web.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-Light_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-Book_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-Medium_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-Semibold_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-Semibold_Web.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-Bold_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'HCo Tungsten Narrow';
	src:url('./woff2/TungstenNarrow-Black_Web.woff2') format('woff2'),
		url('./woff/TungstenNarrow-Black_Web.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

